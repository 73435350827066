body{
  background-color: rgb(253, 253, 253);
}
.meta-logo {
  width:300px;
  height: auto;
}
.nav-link{
  color:#99b7e5
}
.nav-link:hover{
  color:#ffffff
}