.table > :not(:first-child) {
  border-top: 0px solid currentColor;
}
body,
td,
th,
input,
select,
textarea {
  color: #0a0161;
}
@media screen and (max-width: 375px) {
  .meta-logo {
    width: 220px;
    height: auto;
  }
}
